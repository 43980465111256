import React, { useCallback, useEffect } from "react"
import { Flex } from "@chakra-ui/react"
// import Test01mov from "../../images/test01.mov"
// import Test01webm from "../../images/test01.webm"

import { useSelector } from "react-redux"

function supportsHEVCAlpha() {
  if (typeof window !== `undefined`) {
    const navigator = window.navigator
    const ua = navigator.userAgent.toLowerCase()
    const hasMediaCapabilities = !!(
      navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo
    )
    const isSafari =
      ua.indexOf("safari") !== -1 &&
      !(ua.indexOf("chrome") !== -1) &&
      ua.indexOf("version/") !== -1
    return isSafari && hasMediaCapabilities
  } else {
    return true
  }
}

const SchwerpunktIllustration = ({ slides }) => {
  const CURRENT_SLIDE = useSelector(state => state.schwerpunktSlider)
  console.log("CURRENT SLIDE", CURRENT_SLIDE)
  const TEMPORARY_LINKS = [
    slides[CURRENT_SLIDE.current]?.acf_schwerpunkt_card?.illustrationsvideo?.mov
      ?.localFile?.publicURL,
    slides[CURRENT_SLIDE.current]?.acf_schwerpunkt_card?.illustrationsvideo
      ?.webm?.localFile?.publicURL,
  ]
  return (
    <Flex
      alignItems="flex-end"
      mt={["-100px", "-100px", "0px", "0px"]}
      width={["100%", "70%", "100%", "100%"]}
      height={["488px", "488px", "477px", "477px"]}
    >
      {TEMPORARY_LINKS[0] && TEMPORARY_LINKS[1] ? (
        <video
          id="player"
          muted
          autoPlay
          preload="auto"
          playsInline={true}
          src={supportsHEVCAlpha() ? TEMPORARY_LINKS[0] : TEMPORARY_LINKS[1]}
        ></video>
      ) : (
        ""
      )}
    </Flex>
  )
}

export default SchwerpunktIllustration
